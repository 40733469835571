// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "calvin-theme.scss"; // for $calvin-maroon and $calvin-gold and $calvin-warn
@import "calvin-typography.scss"; // for custom typography

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$Sivvu-primary: mat-palette($calvin-maroon);
$Sivvu-accent: mat-palette($calvin-gold);

// The warn palette is optional (defaults to red).
$Sivvu-warn: mat-palette($calvin-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Sivvu-theme: mat-light-theme(
  (
    color: (
      primary: $Sivvu-primary,
      accent: $Sivvu-accent,
      warn: $Sivvu-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($Sivvu-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  margin: 0%;
  font-family: "Roboto";
}

p {
  font-size: 16;
  letter-spacing: 0.5px;
  line-height: 2;
}

h1 {
  text-align: center;
}

h2 {
  text-align: center;
}

h3 {
  text-align: center;
}

footer {
  text-align: left;
  padding: 3px;
  color: black;
}

// makes content within the body more "centered" on the page
.content-wrapper {
  padding-left: 10%;
  padding-right: 10%;
  //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (min-width: 1000px) {
  .content-wrapper {
    padding-left: 20%;
    padding-right: 20%;
    // margin-left: 15%;
    // padding-left: 5%;
    // margin-right: 15%;
    // padding-right: 5%;
    // padding-top: 5%;
  }
}
// end of content-wrapper

#logo-container {
  flex: 1;
}

// styling for the "SIVVU" in the far left side of navbar
#sivvu-nav-text {
  color: white;
  text-decoration: none;
}

// temporary red outline
// * {
//   border: 1px solid red;
// }

mat-card {
  margin-top: 5vh;
}

.activebutton {
  box-shadow: 5px 8px 15px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  border: 1px solid gold;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  padding: 6px 24px;
  text-shadow: 0 1px 2px black;
  outline: 0;
}

.navbutton {
  font-size: x-large;
}

.content-wrapper .mat-raised-button {
  margin-left: 5px;
  margin-right: 15px;
  margin-bottom: 10px;
}

// -------------- styling for mat-grid-list ---------------
mat-grid-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.background {
  background-image: url(assets/img/chem.png);
  min-height: calc(100% - 64px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  z-index: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .background {
    height: calc(100% - 56px);
  }
}

.mat-tooltip {
  font-size: 12px !important;
}